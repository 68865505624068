import { Location } from "@styled-icons/zondicons/Location";
import { Smartphone } from "@styled-icons/material-outlined/Smartphone";
import { EmailOutline } from "@styled-icons/evaicons-outline/EmailOutline";

export const Footer = () => {
  return (
    <div className="footer">
      <span className="footer-separator" />
      {/* <div className="footer-content-container">
        <Location className="footer-content-icon" />
        <div>
          <h1 className="footer-content-address-title">Address</h1>
          <p className="footer-content-paragraph">Heart Connection International</p>
          <p className="footer-content-paragraph">P.O. Box 3251</p>
          <p className="footer-content-paragraph">Camp Hill, PA</p>
          <p className="footer-content-paragraph">17011-9997</p>
        </div>
      </div> */}
      <div className="footer-content-container">
        <Smartphone className="footer-content-icon" />
        <div>
          <h1 className="footer-content-address-title mb-1-forced">Phone</h1>
          <a href={"tel:(530) 339-1294"} className="footer-content-paragraph">
            (530) 339-1294
          </a>
        </div>
      </div>
      <div className="footer-content-container">
        <EmailOutline className="footer-content-icon" />
        <div>
          <h1 className="footer-content-address-title">Email</h1>
          <a
            href="mailto:HeartConnectionIntl@proton.me?subject=New Message: HeartConnectionMinistries.com&body="
            className="footer-content-paragraph"
          >
            HeartConnectionIntl@proton.me
          </a>
        </div>
      </div>
    </div>
  );
};
